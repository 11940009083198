.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}





/*******************************/
/********* General CSS *********/
/*******************************/
body {
  color: #666666;
  background: #dddddd;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
/* body{
  font-family: 'Bungee Inline', cursive;

} */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #030f27;
}
.headermain{
  width: 100%;
  background:white;
  color: #121518;
}

/* a {
  color: #666666;
  transition: .3s;
} */

/* a:hover,
a:active,
a:focus {
  color: #59D5E0;
  outline: none;
  text-decoration: none;
} */

.btn:focus {
  box-shadow: none;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
  background: #ffffff;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #59D5E0;
  color: #121518;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  color: #59D5E0;
  background: #121518;
}

.back-to-top i {
  padding-top: 10px;
}

.btn {
  transition: .3s;
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  width: 100%;
}

.top-bar h3, .top-bar p, .top-bar a {
  color: black !important;
}

.top-bar .logo {
  /* padding: 15px 0; */
  height: 100%;
  display: inline-flex;
  text-align: left;
  overflow: hidden;
}

.top-bar img{
  float: left;
}

.sticky{
  position: fixed !important;
    width: 100% !important;
    top: 0px !important;
    z-index: 10000 !important;
}
.subheader {
  margin: auto !important;
  max-width: 1560px;
  padding: 8px;
}
.logo-div{
  display: flex !important;
}
.logo_icon{
  width: auto !important;
  height: 135px !important;
}
.logo_name{
  width: auto !important;
    height: 50px !important;
    margin: 116px 0px 0px 8px !important;
}


@media (max-width: 450px) {
  .logo_name{
    display: none;
  }

}

.m-header{
  display: flex;
}
.topbar-social-icon{
  display: none;

}

.topbar-social-icon .footer-social a {
display: inline-block;
width: 40px;
height: 40px;
padding: 7px 0;
text-align: center;
border: 1px solid rgba(256, 256, 256, .3);
border-radius: 60px;
transition: .3s;
margin: 5px;
color: white;
}

@media (max-width: 920px) {
  .topbar-social-icon {
    display: flex !important;
    justify-content: space-around; 
    align-items: center; 
    margin-right: 10px; 
  }

}

.top-bar .top-bar-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .top-bar-icon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar .top-bar-icon [class^="flaticon-"]::before {
  margin: 0;
  color: #030f27;
  font-size: 40px;
}

.top-bar .top-bar-text {
  padding-left: 15px;
}

.top-bar .top-bar-text h3 {
  margin: 0 0 5px 0;
  color: #030f27;
  font-size: 16px;
  font-weight: 400;
}

.top-bar .top-bar-text p {
  margin: 0;
  color: #030f27;
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .top-bar .logo {
    text-align: center;
  }
}


/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.nav-bar {
  background: #343a40 !important;
  transition: .3s;
}



.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 10px 8px 10px;
  color: #ffffff;
}

.nav-link.active {
  color: #59D5E0;
  transition: none;
  border: #59D5E0 solid !important;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #59D5E0;
  transition: none;
}

.nav-bar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

.nav-bar .btn {
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 0;
}

.nav-bar .btn:hover {
  color: #030f27;
  background: #59D5E0;
  border-color: #59D5E0;
}

@media (min-width: 992px) {
  /* .nav-bar {
    padding: 0 75px;
  } */

  .nav-bar.nav-sticky {
    padding: 0;
  }

  .nav-bar .navbar {
    padding: 20px;
  }

  .nav-bar .navbar-brand {
    display: none;
  }

  .nav-bar a.nav-link {
    padding: 8px 15px;
    font-size: 15px;
    text-transform: uppercase;
  }
}

@media (max-width: 991.98px) {
  .nav-bar .navbar {
    padding: 15px;
  }

  .nav-bar a.nav-link {
    padding: 5px;
  }

  .nav-bar .dropdown-menu {
    box-shadow: none;
  }

  .nav-bar .btn {
    display: none;
  }
}


/*******************************/
/******** Carousel CSS *********/
/*******************************/
.carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.carousel .carousel-inner,
.carousel .carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 1;
}

.carousel .carousel-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 170px);
  min-height: 400px;
}

.carousel .carousel-caption p {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.carousel .carousel-caption h1 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 35px;
}

.carousel .carousel-caption .btn {
  padding: 15px 35px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 0;
  transition: .3s;
}

.carousel .carousel-caption .btn:hover {
  color: #030f27;
  background: #59D5E0;
  border-color: #59D5E0;
}

@media (max-width: 767.98px) {
  .carousel .carousel-caption h1 {
    font-size: 40px;
    font-weight: 700;
  }

  .carousel .carousel-caption p {
    font-size: 20px;
  }

  .carousel .carousel-caption .btn {
    padding: 12px 30px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
  }
}

@media (max-width: 575.98px) {
  .carousel .carousel-caption h1 {
    font-size: 30px;
    font-weight: 500;
  }

  .carousel .carousel-caption p {
    font-size: 16px;
  }

  .carousel .carousel-caption .btn {
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
  }
}

.carousel .animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}


/*******************************/
/******* Page Header CSS *******/
/*******************************/
.page-header {
  position: relative;
  margin-bottom: 45px;
  padding: 90px 0;
  text-align: center;
  background: #59D5E0;
}

.page-header h2 {
  position: relative;
  color: #030f27;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.page-header h2::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 2px;
  left: calc(50% - 50px);
  bottom: 0;
  background: #030f27;
}

.page-header a {
  position: relative;
  padding: 0 12px;
  font-size: 22px;
  color: #030f27;
}

.page-header a:hover {
  color: #ffffff;
}

.page-header a::after {
  position: absolute;
  content: "/";
  width: 8px;
  height: 8px;
  top: -2px;
  right: -7px;
  text-align: center;
  color: #121518;
}

.page-header a:last-child::after {
  display: none;
}


.header-responsive{
  display: flex;
    /* -ms-flex-wrap: wrap; */
    flex-wrap: nowrap !important;
    margin-right: -15px !important;
    margin-left: -15px !important
}

@media (max-width: 991.98px) {
  .page-header {
    padding: 60px 0;
  }

  .page-header h2 {
    font-size: 45px;
  }

  .page-header a {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .page-header {
    padding: 45px 0;
  }

  .page-header h2 {
    font-size: 35px;
  }

  .page-header a {
    font-size: 18px;
  }
}


/*******************************/
/******* Section Header ********/
/*******************************/

.section-header p {
  color: #59D5E0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.section-header h2 {
  margin: 17px;
  position: relative;
  font-size: 50px;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 30px;
  }
}


/*******************************/
/********* Feature CSS *********/
/*******************************/
.feature {
  position: relative;
  margin-bottom: 45px;
}

.feature .col-md-12 {
  background: #030f27;
}

.feature .col-md-12:nth-child(2n) {
  color: #030f27;
  background: #FFF7FC;
}

.feature .feature-item {
  min-height: 262px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.feature .feature-icon {
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature .feature-icon::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  top: -20px;
  left: -10px;
  border: 2px dotted #ffffff;
  border-radius: 60px;
  z-index: 1;
}

.feature .feature-icon::after {
  position: absolute;
  content: "";
  width: 79px;
  height: 79px;
  top: -18px;
  left: -9px;
  background: #030f27;
  border-radius: 60px;
  z-index: 2;
}

.feature .col-md-12:nth-child(2n) .feature-icon::after {
  background: #59D5E0;
}

.feature .feature-icon [class^="flaticon-"]::before {
  position: relative;
  margin: 0;
  color: #59D5E0;
  font-size: 60px;
  line-height: 60px;
  z-index: 3;
}

.feature .feature-text {
  padding-left: 30px;
}

.feature .feature-text h3 {
  margin: 0 0 10px 0;
  color: #59D5E0;
  font-size: 25px;
  font-weight: 600;
}

.feature .feature-text p {
  margin: 0;
  color: #59D5E0;
  font-size: 18px;
  font-weight: 400;
}

.feature .col-md-12:nth-child(2n) [class^="flaticon-"]::before,
.feature .col-md-12:nth-child(2n) h3,
.feature .col-md-12:nth-child(2n) p {
  color: #030f27;
}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  position: relative;
  width: 100%;
}
.welcometxt{
  text-align: left;
}
.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {
  position: relative;
  height: 100%;
  width: 100%;
}

.about .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about .about-text p {
  font-size: 16px;
}

.about .about-text a.btn {
  position: relative;
  margin-top: 15px;
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #030f27;
  border-radius: 0;
  background: #59D5E0;
  transition: .3s;
}

.about .about-text a.btn:hover {
  color: #59D5E0;
  background: #030f27;
}

@media (max-width: 767.98px) {
  .about .about-img {
    margin-bottom: 30px;
    height: auto;
  }
}


/*******************************/
/********** Fact CSS ***********/
/*******************************/
.fact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.fact .col-6 {
  display: flex;
  align-items: flex-start;
}

.fact .fact-icon {
  position: relative;
  margin: 7px 15px 0 15px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.fact .fact-icon [class^="flaticon-"]::before {
  margin: 0;
  font-size: 60px;
  line-height: 60px;
  background-image: linear-gradient(#ffffff, #59D5E0);
  -webkit-background-clip: text;
  background-clip: text;
  /* Standard property */
  -webkit-text-fill-color: transparent;
  color: transparent;
  /* Standard property */
}

.fact-text {
  margin: auto !important;
}

.fact .fact-right .fact-icon [class^="flaticon-"]::before {
  background-image: linear-gradient(#ffffff, #030f27);
}

.fact .fact-left,
.fact .fact-right {
  padding-top: 60px;
  padding-bottom: 60px;
}

.fact .fact-text h2 {
  font-size: 35px;
  font-weight: 700;
}

.fact .fact-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.fact .fact-left {
  color: #59D5E0;
  background: #5a5b61 ;
  /* background: #030f27; */
}

.fact .fact-right {
  color: #030f27;
  background: #59D5E0;
}

.fact .fact-left h2 {
  color: #59D5E0;
}


/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.service .service-img {
  position: relative;
  overflow: hidden;
}

.service .service-img img {
  width: 100%;
  height: 350px !important;
}

.service .service-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 15, 39, .7);
  transition: .5s;
  opacity: 0;
}

.service .service-item:hover .service-overlay {
  opacity: 1;
}

.service .service-overlay p {
  margin: 0;
  color: #ffffff;
}

.service .service-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service .service-text h3 {
  margin: 0;
  padding: 0 15px 0 85px;
  width: calc(100% - 60px);
  font-size: 20px;
  font-weight: 700;
  color: #59D5E0;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.service .service-item a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #59D5E0;
  border-radius: 0;
  transition: .3s;
}

.service .service-item:hover a.btn {
  color: #ffffff;
}


/*******************************/
/********** Video CSS **********/
/*******************************/
.video {
  position: relative;
  margin: 45px 0;
  height: 100%;
  min-height: 500px;
  /* background: linear-gradient(rgba(3, 15, 39, .9), rgba(3, 15, 39, .9)), url(../img/carousel-1.jpg); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video .btn-play {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #59D5E0;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: #59D5E0;
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play:hover:after {
  background-color: darken(#59D5E0, 10%);
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #030f27;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}


/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.team .team-img {
  position: relative;
}

.team .team-img img {
  height: 300px !important;
}

.team .team-text {
  position: relative;
  padding: 25px 15px;
  text-align: center;
  background: #030f27;
  transition: .5s;
}

.team .team-text h2 {
  font-size: 20px;
  font-weight: 600;
  color: #59D5E0;
  transition: .5s;
}

.team .team-text p {
  margin: 0;
  color: #ffffff;
}

.team .team-item:hover .team-text {
  background: #59D5E0;
}

.team .team-item:hover .team-text h2 {
  color: #030f27;
  letter-spacing: 1px;
}

.team .team-social {
  position: absolute;
  width: 100px;
  top: 0;
  left: -50px;
  display: flex;
  flex-direction: column;
  font-size: 0;
}

.team .team-social a {
  position: relative;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ffffff;
}

.team .team-item:hover .team-social a:first-child {
  background: #00acee;
  left: 50px;
  transition: .3s 0s;
}

.team .team-item:hover .team-social a:nth-child(2) {
  background: #3b5998;
  left: 50px;
  transition: .3s .1s;
}

.team .team-item:hover .team-social a:nth-child(3) {
  background: #0e76a8;
  left: 50px;
  transition: .3s .2s;
}

.team .team-item:hover .team-social a:nth-child(4) {
  background: #3f729b;
  left: 50px;
  transition: .3s .3s;
}


/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.faqs .row {
  position: relative;
}

.faqs .row::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: calc(50% - .5px);
  background: #59D5E0;
}

.faqs #accordion-1 {
  padding-right: 15px;
}

.faqs #accordion-2 {
  padding-left: 15px;
}

@media(max-width: 767.98px) {
  .faqs .row::after {
    display: none;
  }

  .faqs #accordion-1,
  .faqs #accordion-2 {
    padding: 0;
  }

  .faqs #accordion-2 {
    padding-top: 15px;
  }
}

.faqs .card {
  margin-bottom: 15px;
  border: none;
  border-radius: 0;
}

.faqs .card:last-child {
  margin-bottom: 0;
}

.faqs .card-header {
  padding: 0;
  border: none;
  background: #ffffff;
}

.faqs .card-header a {
  display: block;
  padding: 10px 25px;
  width: 100%;
  color: #121518;
  font-size: 16px;
  line-height: 40px;
  border: 1px solid rgba(0, 0, 0, .1);
  transition: .5s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"] {
  background: #59D5E0;
}

.faqs .card-header [data-toggle="collapse"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f067";
  float: right;
  color: #59D5E0;
  font-size: 12px;
  font-weight: 900;
  transition: .5s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f068";
  float: right;
  color: #030f27;
  font-size: 12px;
  font-weight: 900;
  transition: .5s;
}

.faqs .card-body {
  padding: 20px 25px;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-top: none;
}


/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  margin: 45px 0;
  padding: 90px 0;
  text-align: center;
  /* background: linear-gradient(rgba(3, 15, 39, .9), rgba(3, 15, 39, .9)), url(../img/carousel-1.jpg); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial .container {
  max-width: 760px;
}

.about-page .testimonial {
  padding-bottom: 90px;
}

.testimonial .testimonial-slider-nav {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.testimonial .testimonial-slider-nav .slick-slide {
  position: relative;
  opacity: 0;
  transition: .5s;
}

.testimonial .testimonial-slider-nav .slick-active {
  opacity: 1;
  transform: scale(1.3);
}

.testimonial .testimonial-slider-nav .slick-center {
  transform: scale(1.8);
  z-index: 1;
}

.testimonial .testimonial-slider-nav .slick-slide img {
  position: relative;
  display: block;
  margin-top: 37px;
  width: 100%;
  height: auto;
  border-radius: 100px;
}

.testimonial .testimonial-slider {
  position: relative;
  margin-top: 15px;
  padding-top: 50px;
}

.testimonial .testimonial-slider::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 50px;
  top: 0;
  left: calc(50% - 30px);
  /* background: url(../img/quote.png) top center no-repeat; */
}

.testimonial .testimonial-slider h3 {
  color: #59D5E0;
  font-size: 22px;
  font-weight: 700;
}

.testimonial .testimonial-slider h4 {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial .testimonial-slider p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}


/*******************************/
/*********** Blog CSS **********/
/*******************************/
.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.blog .blog-img {
  position: relative;
  overflow: hidden;
}

.blog .blog-img img {
  width: 100%;
}

.blog .blog-title {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog .blog-title h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 18px;
  font-weight: 700;
  color: #59D5E0;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog .blog-title a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #59D5E0;
  border-radius: 0;
  transition: .3s;
}

.blog .blog-item:hover a.btn {
  color: #ffffff;
}

.blog .blog-meta {
  position: relative;
  padding: 25px 0 10px 0;
  background: #f3f6ff;
}

.blog .blog-meta::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 1px;
  left: calc(50% - 50px);
  bottom: 0;
  background: #59D5E0;
}

.blog .blog-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  color: #666666;
}

.blog .blog-meta p a {
  margin-left: 5px;
  font-style: normal;
}

.blog .blog-text {
  padding: 10px 25px 25px 25px;
  background: #f3f6ff;
}

.blog .blog-text p {
  margin: 0;
  font-size: 16px;
}

.blog .pagination .page-link {
  color: #030f27;
  border-radius: 0;
  border-color: #59D5E0;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #59D5E0;
  background: #030f27;
}

.blog .pagination .disabled .page-link {
  color: #999999;
}


/*******************************/
/******* Single Post CSS *******/
/*******************************/
.single {
  position: relative;
  padding: 45px 0;
}

.single .single-content {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single .single-content img {
  margin-bottom: 20px;
  width: 100%;
}

.single .single-tags {
  margin: -5px -5px 41px -5px;
  font-size: 0;
}

.single .single-tags a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #dddddd;
}

.single .single-tags a:hover {
  color: #59D5E0;
  background: #030f27;
}

.single .single-bio {
  margin-bottom: 45px;
  padding: 30px;
  background: #f3f6ff;
  display: flex;
}

.single .single-bio-img {
  width: 100%;
  max-width: 100px;
}

.single .single-bio-img img {
  width: 100%;
}

.single .single-bio-text {
  padding-left: 30px;
}

.single .single-bio-text h3 {
  font-size: 20px;
  font-weight: 700;
}

.single .single-bio-text p {
  margin: 0;
}

.single .single-related {
  margin-bottom: 45px;
}

.single .single-related h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .related-slider {
  position: relative;
  margin: 0 -15px;
  width: calc(100% + 30px);
}

.single .related-slider .post-item {
  margin: 0 15px;
}

.single .post-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.single .post-item .post-img {
  width: 100%;
  max-width: 80px;
}

.single .post-item .post-img img {
  width: 100%;
}

.single .post-item .post-text {
  padding-left: 15px;
}

.single .post-item .post-text a {
  font-size: 16px;
  font-weight: 400;
}

.single .post-item .post-meta {
  display: flex;
  margin-top: 8px;
}

.single .post-item .post-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}

.single .post-item .post-meta p a {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
}

.single .related-slider .owl-nav {
  position: absolute;
  width: 90px;
  top: -55px;
  right: 15px;
  display: flex;
}

.single .related-slider .owl-nav .owl-prev,
.single .related-slider .owl-nav .owl-next {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030f27;
  background: #59D5E0;
  font-size: 16px;
  transition: .3s;
}

.single .related-slider .owl-nav .owl-prev:hover,
.single .related-slider .owl-nav .owl-next:hover {
  color: #59D5E0;
  background: #030f27;
}

.single .single-comment {
  position: relative;
  margin-bottom: 45px;
}

.single .single-comment h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-list {
  list-style: none;
  padding: 0;
}

.single .comment-child {
  list-style: none;
}

.single .comment-body {
  display: flex;
  margin-bottom: 30px;
}

.single .comment-img {
  width: 60px;
}

.single .comment-img img {
  width: 100%;
}

.single .comment-text {
  padding-left: 15px;
  width: calc(100% - 60px);
}

.single .comment-text h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}

.single .comment-text span {
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}

.single .comment-text .btn {
  padding: 3px 10px;
  font-size: 14px;
  color: #030f27;
  background: #dddddd;
  border-radius: 0;
}

.single .comment-text .btn:hover {
  background: #59D5E0;
}

.single .comment-form {
  position: relative;
}

.single .comment-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-form form {
  padding: 30px;
  background: #f3f6ff;
}

.single .comment-form form .form-group:last-child {
  margin: 0;
}

.single .comment-form input,
.single .comment-form textarea {
  border-radius: 0;
}

.single .comment-form .btn {
  padding: 15px 30px;
  color: #030f27;
  background: #59D5E0;
}

.single .comment-form .btn:hover {
  color: #59D5E0;
  background: #030f27;
}


/**********************************/
/*********** Sidebar CSS **********/
/**********************************/
.sidebar {
  position: relative;
  width: 100%;
}

@media(max-width: 991.98px) {
  .sidebar {
    margin-top: 45px;
  }
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

.sidebar .sidebar-widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
  font-size: 30px;
  font-weight: 700;
}

.sidebar .sidebar-widget .widget-title::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #59D5E0;
}

.sidebar .sidebar-widget .search-widget {
  position: relative;
}

.sidebar .search-widget input {
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 0;
}

.sidebar .search-widget input:focus {
  box-shadow: none;
}

.sidebar .search-widget .btn {
  position: absolute;
  top: 6px;
  right: 15px;
  height: 40px;
  padding: 0;
  font-size: 25px;
  color: #59D5E0;
  background: none;
  border-radius: 0;
  border: none;
  transition: .3s;
}

.sidebar .search-widget .btn:hover {
  color: #030f27;
}

.sidebar .sidebar-widget .recent-post {
  position: relative;
}

.sidebar .sidebar-widget .tab-post {
  position: relative;
}

.sidebar .tab-post .nav.nav-pills .nav-link {
  color: #59D5E0;
  background: #030f27;
  border-radius: 0;
}

.sidebar .tab-post .nav.nav-pills .nav-link:hover,
.sidebar .tab-post .nav.nav-pills .nav-link.active {
  color: #030f27;
  background: #59D5E0;
}

.sidebar .tab-post .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.sidebar .tab-post .tab-content .container {
  padding: 0;
}

.sidebar .sidebar-widget .category-widget {
  position: relative;
}

.sidebar .category-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .category-widget ul li {
  margin: 0 0 12px 22px;
}

.sidebar .category-widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar .category-widget ul li a {
  display: inline-block;
  line-height: 23px;
}

.sidebar .category-widget ul li::before {
  position: absolute;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #59D5E0;
  left: 1px;
}

/* .sidebar .category-widget ul li span {
  display: inline-block;
  float: right;
} */
.sidebar .category-widget ul li span {
  float: right;
}


.sidebar .sidebar-widget .tag-widget {
  position: relative;
  margin: -5px -5px;
}

.single .tag-widget a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  border: 1px solid #dddddd;
}

.single .tag-widget a:hover {
  color: #59D5E0;
  background: #030f27;
}

.sidebar .image-widget {
  display: block;
  width: 100%;
  overflow: hidden;
}

.sidebar .image-widget img {
  max-width: 100%;
  transition: .3s;
}

.sidebar .image-widget img:hover {
  transform: scale(1.1);
}


/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.portfolio {
  position: relative;
  padding: 45px 0;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: -15px 0 25px 0;
  list-style: none;
  font-size: 0;
  text-align: center;
}

.portfolio #portfolio-flters li,
.portfolio .load-more .btn {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  padding: 8px 15px;
  color: #030f27;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0;
  background: #59D5E0;
  border: none;
  transition: .3s;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #030f27;
  color: #59D5E0;
}

.portfolio .load-more {
  text-align: center;
}

.portfolio .load-more .btn {
  padding: 15px 35px;
  font-size: 16px;
  transition: .3s;
}

.portfolio .load-more .btn:hover {
  color: #59D5E0;
  background: #030f27;
}

.portfolio .portfolio-warp {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.portfolio .portfolio-img {
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-img img {
  width: 100%;
  transition: .3s;
}

.portfolio .portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio .portfolio-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 15, 39, .7);
  transition: .5s;
  opacity: 0;
}

.portfolio .portfolio-warp:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio .portfolio-overlay p {
  margin: 0;
  color: #ffffff;
}

.portfolio .portfolio-text {
  display: flex;
  align-items: center;
  height: 60px;
  background: #030f27;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.portfolio .portfolio-text h3 {
  margin: 0;
  padding: 0 15px 0 25px;
  width: calc(100% - 60px);
  font-size: 20px;
  font-weight: 700;
  color: #59D5E0;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.portfolio .portfolio-warp a.btn {
  width: 60px;
  height: 60px;
  padding: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 100;
  color: #030f27;
  background: #ffffff;
  border-radius: 0;
  transition: .3s;
}

.portfolio .portfolio-warp:hover a.btn {
  color: #ffffff;
  background: #59D5E0;
}


/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.contact .col-md-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact .col-md-6:first-child {
  background: #343a40 !important;
}

.contact .col-md-6:last-child {
  background: 	#032f3c;
}

.contact .contact-info {
  position: relative;
  width: 100%;
  padding: 0 15px;
}
.querydiv{
  margin-top: 20px;
}
.contact .contact-item {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid rgba(256, 256, 256, .2);
}

.contact .contact-item [class^="flaticon-"]::before {
  margin: 0;
  color: #59D5E0;
  font-size: 40px;
}

.contact .contact-text {
  position: relative;
  width: auto;
  /* padding-left: 20px; */
  margin: auto;
}

.contact .contact-text h2 {
  color: #59D5E0;
  font-size: 20px;
  font-weight: 600;
}

.contact .contact-text p {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
}

.contact .contact-item:last-child {
  margin-bottom: 0;
}

.contact .contact-form {
  position: relative;
  padding: 0 15px;
}

.contact .contact-form input {
  color: #ffffff;
  height: 40px;
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(256, 256, 256, .4);
  background: transparent;
}

.contact .contact-form textarea {
  color: #ffffff;
  height: 185px;
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(256, 256, 256, .4);
  background: transparent;
}

.contact .contact-form input:focus,
.contact .contact-form textarea {
  box-shadow: none;
}

.contact .contact-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.contact .contact-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.contact .contact-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.contact .contact-form .btn {
  padding: 16px 30px;
  font-size: 16px;
  font-weight: 600;
  color:  #030f27;
  background: #ffffff;
  border: none;
  border-radius: 0;
  transition: .3s;
}



.contact .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}


/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer-container{
  position: relative;
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
}
.footer {
  position: relative;
  margin-top: 45px;
  padding-top: 90px;
  background: #343a40 !important;
  color: #ffffff;
}
.footeritems{
  text-align: left !important;
  padding-left: 42px;
}
.footer .footer-contact,
.footer .footer-link,
.footer .newsletter {
  position: relative;
  margin-bottom: 45px;
}

.footer h2 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #59D5E0;
}

/* .footer h2::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #59D5E0;
} */

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
  transition: .3s;
}

.footer .footer-link a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  color: #59D5E0;
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 7px 0;
  text-align: center;
  border: 1px solid rgba(256, 256, 256, .3);
  border-radius: 60px;
  transition: .3s;
}

.footer .footer-social a i {
  font-size: 15px;
  color: #ffffff;
}

.footer .footer-social a:hover {
  background: #59D5E0;
  border-color: #59D5E0;
}

.footer .footer-social a:hover i {
  color: #030f27;
}

.footer .newsletter .form {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.footer .newsletter input {
  height: 50px;
  border: 2px solid #121518;
  border-radius: 0;
}

.footer .newsletter .btn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #59D5E0;
  background: #121518;
  border-radius: 0;
  border: 2px solid #59D5E0;
  transition: .3s;
}

.footer .newsletter .btn:hover {
  color: #121518;
  background: #59D5E0;
}

.footer .footer-menu .f-menu {
  position: relative;
  padding: 15px 0;
  font-size: 0;
  text-align: center;
  border-top: 1px solid rgba(256, 256, 256, .1);
  border-bottom: 1px solid rgba(256, 256, 256, .1);
}

.footer .footer-menu .f-menu p {
  color: #ffffff;
  font-size: 16px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu .f-menu .p1 {
  color: #59D5E0;


}
.footer .footer-menu .f-menu .p1:hover {
  color: #ddd9d1;
  cursor: pointer;
}

.footer .footer-menu .f-menu p:hover {
  color: #59D5E0;
}

.footer .footer-menu .f-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}


.footer .copyright {
  padding: 30px 15px;
}

.footer .copyright p {
  margin: 0;
  color: #ffffff;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #59D5E0;
  font-weight: 500;
  letter-spacing: 1px;
}

.footer .copyright p a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {

  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
}
